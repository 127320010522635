<template>
    <div>

    </div>
</template>
<script>
    export default {

        props: {
            val: {}
        },
        data() {
            return {

            }
        },

        created() {

        },
        methods: {

        }
    }
</script>
<style lang="scss" scoped>
    @import "index.scss";
</style>